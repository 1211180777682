/*! (c) Lavaimagem - https://www.lavaimagem.com/ */

// Variables

$color-black: #000;
$color-white: #fff;
$color-light: #fdfeff;
$color-dark-blue-a: #2c3a67;
$color-dark-blue-b: #323748;
$color-light-gray-a: #dfe6eb;
$color-light-gray-b: #bfc7ce;
$color-dark-gray-a: #777c8b;
$color-dark-gray-b: #6e6e6e;
$color-light-blue-a: #3c79db;
$color-light-blue-b: #7280ad;
$color-blue-footer: #77bad6;

$color-custom-defaul-gray: #EFEFEF;
$color-custom-blue: #39cdd9;
$color-custom-blue-darker: #2ba6b9;
$color-custom-article: #404b57;
$color-custom-blue-hover: darken($color-custom-blue, 5%);
$color-custom-blue-darker-hover: darken($color-custom-blue-darker, 5%);
$color-custom-date-featured: rgba($color-dark-blue-a, .5);
$color-custom-sidebar-highlighter: rgba($color-black, .5);

$color-light-blue-a-hover: lighten($color-light-blue-a, 5%);

$color-shadow-menu: rgba($color-dark-blue-a, .7);
$color-shadow-player: rgba($color-light-blue-a, .7);
$color-shadow-top-menu: rgba($color-black, .25);
$color-shadow-news-image: rgba($color-black, .16);
$color-shadow-album: rgba($color-black, .58);
$color-shadow-image-hover: rgba($color-light-blue-a, .25);
$color-shadow-sidebar: rgba($color-light-blue-b, .25);
$color-shadow-tvbanner: rgba($color-dark-blue-a, .25);
$color-shadow-tvbutton: rgba($color-custom-blue, .5);
$color-shadow-player: rgba($color-custom-article, .15);
$color-shadow-input: rgba($color-light-blue-a, .34);
$color-shadow-table: rgba($color-light-gray-a, .5);

$font-family-grad: 'grad', serif;
$font-family-nova: 'proxima-nova', sans-serif;
$font-family-monospaced: 'Lucida Sans', sans-serif;

$font-weight-light: 300;
$font-weight-normal: 500;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

// -----------------------------------------------------------------------------

// General

body {
    font-family: $font-family-nova;
    font-size: 12px;
}

* {
    -moz-margin-end: 0;
    -moz-margin-start: 0;
    -moz-padding-end: 0;
    -moz-padding-start: 0;
    -webkit-margin-after: 0;
    -webkit-margin-before: 0;
    -webkit-margin-end: 0;
    -webkit-margin-start: 0;
    -webkit-padding-start: 0;
    margin-inline-end: 0;
    margin-inline-start: 0;
    padding-inline-end: 0;
    padding-inline-start: 0;
}

a {
    color: $color-light-blue-a;

    &:hover {
        color: $color-light-blue-b;
    }
}

.clear {
    clear: both;
}

p {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.right {
    float: right;
}

h2 {
    color: $color-light-blue-b;
    font-size: 1.8em;
    font-weight: $font-weight-extrabold;
}

font {
    font-family: $font-family-nova; // Fix
    font-size: 15px; // Fix
}

.imgbox {
    background-position: center center;
    background-repeat: no-repeat;
}

.spacer {
    display: block;
    height: 30px;
}

.magnified {
    font-size: 1.2em;
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 6px $color-shadow-top-menu;
}

::-webkit-scrollbar-thumb {
    background: $color-dark-gray-b;
    border-radius: 10px;
    box-shadow: inset 0 0 6px $color-shadow-top-menu;
}

.modal-body {
    font-size: 1.3em;
}

.btn-subscribe,
.btn-unsubscribe {
    i {
        font-size: 1.2em;
        margin-top: -3px;
        vertical-align: middle;
    }
}

.btn-unsubscribe {
    display: none;
}

// -----------------------------------------------------------------------------

// Menus

.logo {
    padding-top: 15px;
}

.publicite-aqui {
    border: 1px solid $color-light-gray-b;
    font-weight: $font-weight-bold;
    height: 55px;
    padding: 19px;
    position: absolute;
    right: 30px;
    text-transform: uppercase;
    top: 28px;

    a {
        color: $color-light-gray-b;

        &:hover {
            color: $color-dark-gray-a;
            text-decoration: none;
        }
    }
}

.menu-top {
    background-color: $color-dark-blue-a;
    color: $color-white;
    height: 55px;
    margin-bottom: 30px;
    margin-top: 15px;

    .menu-item-group {
        ul {
            list-style-type: none;
            padding: 0;
        }

        li {
            border-bottom: 4px solid $color-dark-blue-a;
            display: inline-block;
            height: 55px;
            margin-right: 30px;
            padding: 0 25px;
            padding-top: 18px;
            text-transform: uppercase;
        }

        a {
            color: $color-light-blue-b;
            font-weight: $font-weight-bold;
            text-shadow: 0 1px 2px $color-shadow-top-menu;

            &:hover {
                color: $color-light-gray-a;
            }

            &.active {
                color: $color-light-gray-a;

                li {
                    border-bottom: 4px solid $color-light-gray-a;
                }
            }
        }
    }

    .left-menu {
        left: 0;
        position: absolute;
    }

    .right-menu {
        position: absolute;
        right: 0;

        div {
            float: left;
        }

        .tv {
            font-weight: $font-weight-bold;
            margin-right: 50px;
            padding-top: 19px;
            text-shadow: 0 1px 2px $color-shadow-top-menu;

            a {
                color: $color-white;
            }
        }

        .notif {
            cursor: pointer;
            margin-right: 30px;
            padding-top: 19px;
            text-shadow: 0 1px 2px $color-shadow-top-menu;

            i {
                font-size: 2.2em;
                margin-top: -5px;
                vertical-align: middle;
            }
        }

        .search,
        .sidebar-toggle {
            cursor: pointer;
            height: 55px;
            padding-right: 10px;
            padding-top: 12px;

            i {
                font-size: 2.6em;
            }

            .menuclose {
                display: none;
            }
        }

        .search {
            i {
                position: absolute;
                right: 80px;
            }

            input {
                background-color: transparent;
                border: 0;
                border-bottom: 1px solid $color-white;
                box-shadow: none;
                color: $color-white;
                height: 30px;
                opacity: 0;
                outline: none;
                padding: 10px;
                transition: width .6s, opacity .6s;
                width: 0;
                z-index: 2;

                &:focus {
                    opacity: 1;
                    outline: none;
                    width: 200px;
                }

                &::-webkit-input-placeholder {
                    color: $color-light-gray-b;
                }

                &::-moz-placeholder {
                    color: $color-light-gray-b;
                }

                &:-ms-input-placeholder {
                    color: $color-light-gray-b;
                }
            }
        }

        .sidebar-toggle,
        .open {
            margin-right: 10px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .open {
            background: $color-white;
            color: $color-dark-blue-a;

            .menuopen {
                display: none;
            }

            .menuclose {
                display: inline-block;
            }
        }
    }

    .play-btn {
        color: $color-white;
    }
}

.sidebar {
    background: $color-white;
    border-bottom: 1px solid $color-light-gray-b;
    border-left: 1px solid $color-light-gray-b;
    box-shadow: 0 0 6px 0 $color-shadow-sidebar;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 20px;
    position: fixed;
    right: -330px;
    top: 0;
    transition: .5s;
    width: 330px;
    z-index: 15;

    .group {
        padding: 0 20px;
        padding-top: 20px;
        width: 50%;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        margin-top: 12px;

        a {
            color: $color-light-blue-b;

            &:hover {
                color: $color-light-blue-a;
                text-decoration: none;
            }

            &.active {
                color: $color-light-blue-a;
            }
        }
    }

    h1 {
        color: $color-dark-gray-a;
        font-size: 1.1em;
        font-weight: $font-weight-bold;
        margin: 0;
    }

    .mobile {
        text-align: center;
        width: 100%;

        span {
            color: $color-light-gray-b;
            font-weight: $font-weight-extrabold;
        }

        a {
            color: $color-light-blue-b;
            font-weight: $font-weight-extrabold;
            text-transform: uppercase;

            li {
                border-bottom: 1px solid $color-light-gray-a;
                padding-bottom: 18px;
                padding-top: 8px;
            }

            &:last-child {
                li {
                    border: 0;
                    box-shadow: inset 0 -10px 6px -10px $color-shadow-sidebar;
                }
            }
        }
    }
}

.sidebar-highlighter {
    background: $color-custom-sidebar-highlighter;
    height: 100vh;
    margin-left: -15px;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    transition: opacity .3s linear;
    width: 100%;
    z-index: 10;

    &.visible {
        opacity: 1;
        pointer-events: auto;
    }
}

.menu-top-fixed {
    margin-left: 0;
    margin-top: 0;
    position: fixed !important; // Tem que ser, para já...
    top: 0;
    width: 100%;
    z-index: 5;
}

.menu-top-fixed-spacer {
    margin-bottom: 100px;
}

.logo,
.menu-top,
.container,
footer {
    left: 0;
    position: relative;
    transition: left .5s;
}

// -----------------------------------------------------------------------------

// Category navigation

.catnav {
    ul {
        list-style-type: none;
        padding: 0;
    }

    a {
        color: $color-light-blue-b;
        text-transform: uppercase;

        &:hover {
            border-bottom: 1px solid $color-light-blue-a;
            color: $color-light-blue-a;
            text-decoration: none;
        }
    }

    li {
        display: inline-block;
        margin-right: 20px;
    }

    .active {
        a {
            border-bottom: 1px solid $color-light-blue-a;
            color: $color-light-blue-a;

            &:hover {
                color: $color-light-blue-b;
            }
        }
    }
}

@media (max-width: 991px) {
    .catnav {
        margin-bottom: 0;
        margin-top: 40px;
        width: 100%;

        ul {
            display: block;
        }
    }
}

// -----------------------------------------------------------------------------

// Content boxes

.featured-box {
    border: 1px solid $color-light-gray-b;
    box-shadow: 0 6px 12px 0 $color-shadow-sidebar;
    cursor: pointer;

    .title,
    .article {
        display: block;
    }

    .title {
        border-top: 1px solid $color-shadow-news-image;
        color: $color-dark-blue-a;
        font-family: $font-family-grad;
        font-size: 1.5em;
        font-weight: $font-weight-bold;
        padding: 16px 10px;
    }

    .article {
        color: $color-dark-gray-a;
        font-family: $font-family-grad;
        height: 95px;
        padding: 0 15px;
        padding-bottom: 10px;
    }

    .date {
        color: $color-custom-date-featured;
        display: block;
        font-size: .8em;
        font-weight: bold;
        height: 24px;
        padding-right: 10px;
        text-align: right;
    }

    &:hover {
        background-color: $color-light;
        border: 1px solid $color-dark-blue-a;
        box-shadow: 0 6px 12px 0 $color-shadow-image-hover;

        .article {
            color: $color-dark-blue-a;
        }
    }
}

@media (min-width: 768px) {
    .fifty {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width: 991.98px) {
    .fifty {
        padding: 0;
    }

    .fifty-articles {
        article {
            .row {
                margin-right: 0;
            }
        }
    }
}

.article-box {
    border-bottom: 1px solid $color-light-gray-a;
    cursor: pointer;
    margin-bottom: 26px;
    padding-bottom: 26px;

    &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .col-lg-4 {
        padding-right: 0;
    }

    .img {
        border: 1px solid $color-light-gray-b;
        box-shadow: 0 2px 6px 0 $color-shadow-sidebar;
        height: 110px;
        width: 100%;
    }

    .title {
        color: $color-light-blue-b;
        display: block;
        font-family: $font-family-grad;
        font-size: 1.3em;
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
        padding-top: 8px;
    }

    .article {
        color: $color-dark-gray-a;
        font-family: $font-family-grad;
        font-size: 1.05em;
    }

    .date {
        color: $color-custom-date-featured;
        display: block;
        float: right;
        font-size: .8em;
        font-weight: bold;
        margin-top: 5px;
        text-align: right;
    }

    &:hover {
        border-bottom: 1px solid $color-dark-blue-a;

        img {
            border: 1px solid $color-dark-blue-a;
            box-shadow: 0 2px 6px 0 $color-shadow-image-hover;
        }

        .article,
        .title {
            color: $color-dark-blue-a;
        }

        &:last-child {
            border-bottom: 0;
        }
    }
}

.compressed-article {
    .article-box {
        img {
            height: 100px;
            width: 110px;
        }
    }
}

@media (max-width: 991px) {
    .article-box,
    .videos-box {
        margin-top: 40px;

        .img {
            background-size: cover;
        }
    }
}

.popular-box {
    .header,
    article {
        border: 1px solid $color-light-gray-a;
        border-bottom: 0;
        text-align: center;
    }

    .header {
        color: $color-dark-gray-a;
        font-size: 1.1em;
        font-weight: $font-weight-bold;
        padding: 6px 0;
    }

    article {
        color: $color-light-blue-b;
        cursor: pointer;
        font-family: $font-family-grad;
        font-weight: $font-weight-bold;
        height: 95px;
        padding: 0 25px;

        &:last-child {
            border-bottom: 1px solid $color-light-gray-a;

            &:hover {
                border-bottom: 1px solid $color-light-blue-a;
            }
        }

        &:hover {
            background: $color-light;
            border: 1px solid $color-light-blue-a;
            border-bottom: 0;
            box-shadow: 0 2px 4px 0 $color-shadow-image-hover;
            color: $color-light-blue-a;
        }

        &:hover + article {
            border-top: 1px solid $color-light-blue-a;
        }
    }

    .title {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}

.newsbtn {
    width: 100%;

    span {
        background: $color-light;
        border: 1px solid $color-light-blue-a;
        box-shadow: 0 2px 4px 0 $color-shadow-image-hover;
        color: $color-light-blue-a;
        cursor: pointer;
        display: block;
        font-weight: $font-weight-extrabold;
        margin-top: 30px;
        padding: 20px;
        text-align: center;
        width: 100%;
    }
}

.videos-box {
    border: 1px solid $color-light-gray-a;

    .header {
        border-bottom: 1px solid $color-light-gray-a;
        color: $color-dark-gray-a;
        font-size: 1.1em;
        font-weight: $font-weight-bold;
        padding: 9px 14px;
    }

    span {
        float: right;
        font-size: .9em;
        text-transform: uppercase;

        i {
            font-size: 1.4em;
            font-weight: $font-weight-bold;
            vertical-align: middle;
        }
    }

    .row {
        padding: 57px 14px;
        padding-bottom: 25px;
    }

    .video {
        cursor: pointer;
        margin-bottom: 30px;

        &:hover {
            img {
                border: 1px solid $color-light-blue-a;
                box-shadow: 0 2px 4px 0 $color-shadow-image-hover;
            }

            .title {
                color: $color-light-blue-a;
            }
        }
    }

    .image {
        float: left;
        width: 50%;

        img {
            border: 1px solid $color-dark-blue-a;
            box-shadow: 0 2px 4px 0 $color-shadow-news-image;
        }
    }

    .title {
        color: $color-light-blue-b;
        float: left;
        padding-top: 5px;
        width: 50%;
    }
}

.news-box-title {
    .content {
        border-bottom: 1px solid $color-light-gray-a;
        color: $color-dark-gray-b;
        font-size: 1.1em;
        font-weight: $font-weight-bold;
        margin-bottom: 15px;
        margin-top: 25px;
        padding-bottom: 10px;

        span {
            float: right;
            text-transform: uppercase;

            i {
                font-size: 1.4em;
                font-weight: $font-weight-bold;
                vertical-align: middle;
            }
        }
    }
}

.news-box {
    padding-bottom: 10px;

    .image,
    .title {
        float: left;
    }

    .image {
        border: 1px solid $color-light-gray-b;
        box-shadow: 0 2px 4px 0 $color-shadow-news-image;
        margin-bottom: 20px;

        .img {
            height: 61px;
            width: 68px;
        }
    }

    .title {
        color: $color-dark-gray-a;
        font-family: $font-family-grad;
        font-size: 1em;
        font-weight: $font-weight-bold;
        height: 63px;
        margin-left: 10px;
        width: 70%;

        span {
            &:first-child {
                color: $color-custom-date-featured;
                display: block;
                font-family: $font-family-nova;
                font-size: .9em;
                font-weight: $font-weight-light;
                padding-top: 8px;
            }

            &:last-child {
                float: left;
            }

            &.date {
                font-family: $font-family-nova;
                font-size: .8em;
                font-weight: $font-weight-bold;
            }
        }

        p,
        p * {
            color: $color-black;
            display: block;
            font-family: $font-family-nova;
            font-weight: $font-weight-normal;
        }
    }

    .col-4 {
        border-right: 1px solid $color-light-gray-a;

        &:last-child {
            border-right: 0;
        }
    }

    .group {
        cursor: pointer;

        &:hover {
            .image {
                border: 1px solid $color-light-blue-a;
                box-shadow: 0 2px 4px 0 $color-shadow-image-hover;
            }

            .title {
                color: $color-light-blue-a;
            }
        }
    }
}

.news-box-line {
    border-bottom: 1px solid $color-light-gray-a;
}

.extra {
    margin-bottom: 35px;
    margin-top: 50px;
}

.videos-list {
    margin-top: 40px;
    padding-bottom: 10px;

    .image,
    .title {
        float: left;
    }

    .image {
        margin-bottom: 40px;
        width: 45%;

        img {
            border: 1px solid $color-dark-blue-a;
            box-shadow: 0 2px 4px 0 $color-shadow-news-image;
        }
    }

    .title {
        color: $color-light-blue-b;
        height: 63px;
        margin-left: 10px;
        width: 45%;
        word-wrap: break-word;

        span {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .group {
        cursor: pointer;

        &:hover {
            img {
                border: 1px solid $color-light-blue-a;
                box-shadow: 0 2px 4px 0 $color-shadow-image-hover;
            }

            .title {
                color: $color-light-blue-a;
            }
        }
    }
}

@media (max-width: 1199px) {
    .videos-list {
        .title {
            width: auto;
        }
    }

    .newsbtn {
        margin-left: 0;
        margin-right: 0;
    }
}

// -----------------------------------------------------------------------------

// Article

.article {
    .col-lg-8 {
        padding-right: 0;
    }

    .col-4 {
        border-left: 1px solid $color-light-gray-a;
        padding-left: 0;
    }

    .title {
        color: $color-dark-blue-a;
        font-family: $font-family-grad;
        font-size: 3.3em;
        font-weight: $font-weight-bold;
        text-align: center;
    }

    .articletitle {
        line-height: 1.3em;
        padding: 5px 145px 30px;

        h4 {
            color: $color-dark-gray-a;
            font-family: $font-family-nova;
            font-size: .4em;
            margin: 0;
            margin-top: 5px;
        }
    }

    .header {
        border-bottom: 1px solid $color-light-gray-a;
        padding: 10px;

        .info {
            color: $color-light-gray-b;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            i {
                font-size: 1.1em;
                font-weight: $font-weight-bold;
                margin-right: 5px;
                margin-top: -4px;
                vertical-align: middle;
            }
        }

        .social {
            color: $color-light-blue-a;
            float: right;

            div {
                float: left;
                margin-left: 2px;

                .button-wpp {
                    background-color: #25d366;
                    color: #eee;
                    border-radius: 3px;
                    font-size: 11px;
                    height: 20px;
                    padding: 0 6px;
                }

                .button-wpp:hover {
                    background-color: darken(#25d366, 5%);
                }

                .button-wpp:focus {
                    background-color: darken(#25d366, 15%);
                }
            }

        }
    }

    .view {
        border-bottom: 1px solid $color-light-gray-a;
        color: $color-light-blue-b;
        font-weight: $font-weight-bold;
        padding: 7px;

        i {
            font-weight: $font-weight-normal;
            vertical-align: middle;
        }

        span {
            float: right;
        }

        a {
            color: $color-light-blue-b;

            &:hover {
                color: $color-light-blue-a;
                text-decoration: none;
            }
        }
    }

    .content {
        color: $color-custom-article;
        margin-bottom: 30px;
        padding: 30px;

        img {
            cursor: pointer;
            height: auto !important; // Fix para a porcaria do CKEditor
            max-width: 100%;
        }

        .image {
            margin: 30px;
            text-align: center;

            img {
                border: 1px solid $color-light-gray-b;
                box-shadow: 0 2px 6px 0 $color-shadow-tvbanner;
            }
        }

        p {
            display: block;
            font-family: $font-family-grad;
            margin: 20px 0;
        }

        // Formatação dos elementos disponíveis no CKEditor

        table {
            border: 1px solid $color-dark-gray-a;
            margin: 10px 0;

            th,
            td {
                padding: 5px 10px;
            }
        }

        ul,
        ol {
            li {
                margin-left: 13px;
            }
        }

        blockquote {
            color: $color-light-blue-b;

            &::before {
                color: $color-light-gray-a;
                content: '>> ';
                margin-right: 10px;
            }
        }
    }

    .gallery-brief {
        height: 80px;
        margin-top: 60px;
        overflow: hidden;
        padding: 10px;
        padding-top: 0;
        text-align: center;

        img {
            border: 1px solid $color-light-gray-b;
            height: 60px !important; // Por causa do !important acima...
            margin-right: 10px;
            margin-top: 10px;

            &:first-child {
                margin-left: 10px;
            }

            &:hover {
                border: 1px solid $color-light-blue-a;
                box-shadow: 0 2px 4px 0 $color-shadow-image-hover;
            }
        }

        .arrow {
            border-bottom: 10px solid transparent;
            border-top: 10px solid transparent;
            cursor: pointer;
            height: 0;
            margin-top: 30px;
            position: absolute;
            width: 0;
        }

        .arrow-left {
            border-right: 10px solid $color-light-blue-a;

            &.disabled {
                border-right: 10px solid $color-dark-gray-b;
            }
        }

        .arrow-right {
            border-left: 10px solid $color-light-blue-a;
            margin-right: 40px;
            right: 0;

            &.disabled {
                border-left: 10px solid $color-dark-gray-b;
            }
        }
    }

    .related {
        padding: 30px;
        padding-top: 10px;

        .header {
            color: $color-dark-gray-a;
            font-weight: $font-weight-bold;
            margin-bottom: 20px;
            text-align: center;
        }

        .image {
            float: left;
            margin-right: 10px;
            width: 50px;

            img {
                border: 1px solid $color-light-gray-b;
                box-shadow: 0 2px 4px 0 $color-shadow-news-image;
            }
        }

        .title {
            color: $color-dark-gray-a;
            float: left;
            font-size: 1em;
            padding-top: 10px;
        }

        article {
            margin-bottom: 20px;

            &:hover {
                cursor: pointer;

                .image {
                    img {
                        border: 1px solid $color-light-blue-a;
                        box-shadow: 0 2px 4px 0 $color-shadow-image-hover;
                    }
                }

                .title {
                    color: $color-light-blue-a;
                }
            }
        }
    }
}

.article-box-title {
    margin-top: 20px;

    .col-4 {
        padding: 0;
    }
}

@media (max-width: 1200px) {
    .article {
        .col-lg-8 {
            padding-right: 15px;
        }

        .content {
            padding-left: 0;
            padding-right: 0;

            .image {
                margin: 0;
                margin-right: 10px;
                width: 100%;
            }
        }

        .title {
            font-size: 2em;
            padding: 5px 15px 40px;
        }
    }
}

// -----------------------------------------------------------------------------

// Static Content

.static-content {
    h2 {
        color: $color-dark-blue-a;
        font-family: $font-family-grad;
        font-size: 2.8em;
        font-weight: $font-weight-bold;
    }

    .content {
        color: $color-custom-article;
        font-size: 1.3em;

        p {
            display: block;
            margin-bottom: 1em;
            margin-left: 0;
            margin-right: 0;
            margin-top: 1em;
        }

        img {
            margin: 20px 0;
        }
    }
}

.content-step {
    margin-bottom: 30px;
}

@media (max-width: 1200px) {
    .static-content {
        .content {
            img {
                width: 100%;
            }
        }
    }
}

// -----------------------------------------------------------------------------

// Menu - Player

.playing {
    color: $color-light-blue-a;
    font-size: .9em;
    left: 10px;
    position: absolute;
    top: 88px;

    b {
        font-weight: $font-weight-extrabold;
        margin-right: 5px;
    }
}

.player {
    background-color: $color-light-blue-a;
    box-shadow: 0 2px 4px 0 $color-shadow-player;
    cursor: pointer;
    float: left;
    font-weight: $font-weight-bold;
    height: 55px;
    padding: 0 10px;
    padding-top: 18px;
    text-shadow: 0 1px 2px $color-shadow-menu;

    &:hover {
        background-color: $color-light-blue-a-hover;
    }

    i {
        font-size: 2.3em;
        margin-top: -5px;
        vertical-align: middle;
    }
}

.onair {
    float: left;

    div {
        float: left;
    }

    .image {
        padding-left: 10px;
        padding-top: 8px;
    }

    .person {
        padding-left: 7px;
        padding-top: 18px;

        i {
            font-size: 1.4em;
            margin-top: -3px;
            vertical-align: middle;
        }
    }
}

// -----------------------------------------------------------------------------

// Player

.player-logo {
    background: $color-white;
    box-shadow: 0 3px 12px 0 $color-shadow-player;
    height: 50px;
    padding-top: 6px;
    z-index: 999;

    span {
        color: $color-dark-blue-a;
        font-size: .9em;
        font-weight: $font-weight-bold;
        margin-left: 8px;
        position: absolute;
        top: 18px; // Desisti
    }
}

.player-menu {
    box-shadow: 0 3px 12px 0 $color-shadow-player;
    height: 50px;
    padding-top: 16px;

    ul {
        display: inline;
        list-style-type: none;
        margin-left: 20px;
        padding: 0;
    }

    li {
        display: inline-block;
        margin-right: 25px;
    }

    a {
        color: $color-light-gray-b;
        font-size: .9em;
        font-weight: $font-weight-bold;
        text-transform: uppercase;

        &:hover {
            color: $color-light-blue-a;
            text-decoration: none;
        }

        &.active {
            color: $color-light-blue-a;

            &:hover {
                border-bottom: 1px solid $color-light-blue-a;
            }
        }
    }

    .social {
        float: right;

        div {
            float: left;
            margin-left: 4px;
        }
    }
}

.player-sidebar {
    background-color: $color-dark-blue-b;
    box-shadow: inset 0 0 30px $color-shadow-player;
    color: $color-white;
    height: calc(100% - 50px);
    max-height: 120vh;
    min-height: 100vh;
    overflow-y: auto;
    text-shadow: 0 1px 2px $color-shadow-top-menu;

    h3 {
        font-size: 1em;
        margin-top: 30px;
        text-align: center;

        i {
            vertical-align: middle;
        }
    }

    ul {
        list-style-type: none;
        margin-left: 10px;
        padding: 0;
    }

    li {
        color: $color-light-blue-b;
        margin-top: 15px;
    }
}

.player-content {
    &.col-lg-9 {
        padding-left: 0;
        padding-right: 0;
    }

    font {
        font-size: 1em; // Fix for "Lista DE NOTÍCIAS"
    }
}

.player-media {
    color: $color-white;
    height: 400px;
    overflow: hidden;
    text-shadow: 0 2px 4px $color-shadow-album;

    .player-playing {
        line-height: 3em;
        margin-left: 60px;
        margin-top: -340px;
        max-width: 630px;
        position: absolute;

        h3 {
            font-size: 1em;
            font-weight: $font-weight-normal;
        }

        span {
            font-size: 3em;
            font-weight: $font-weight-bold;
        }
    }

    .player-next {
        line-height: 2em;
        margin-left: 60px;
        margin-top: -230px;
        position: absolute;

        h3 {
            font-size: 1em;
            font-weight: $font-weight-normal;
        }

        span {
            font-size: 1.1em;
            font-weight: $font-weight-bold;
        }
    }

    .controls {
        margin-left: 60px;
        margin-top: -170px;
        position: absolute;
        width: 200px;

        .slider {
            margin-top: -8px;
            width: 90px;

            .slider-selection {
                background: $color-light-blue-a;
                border-radius: 0;
                height: 4px;
            }

            .slider-track {
                height: 4px;
            }

            .slider-track-high {
                background: $color-light-gray-a;
                border-radius: 0;
                height: 4px;
            }

            .slider-handle {
                background: $color-white;
                box-shadow: 0 0 2px 0 $color-shadow-album;
                height: 9px;
                margin-left: -3px;
                margin-top: 2px;
                width: 9px;
            }
        }

        .player-volume {
            margin-left: 20px;
            margin-top: 10px;
            position: absolute;

            i {
                font-size: 1.8em;
                margin-right: 10px;
            }
        }

        .player-play {
            padding-top: 25px;

            .audio-play {
                i {
                    cursor: pointer;
                    font-size: 3.3em;
                }
            }
        }

        .volume-icon {
            cursor: pointer;
        }
    }

    .art {
        background-color: $color-light-blue-b;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: blur(18px);
        height: 100%;
        position: relative;
        transform: scale(1.3);
        width: 100%;
        z-index: -1;
    }

    .bars {
        height: 130px;
        margin-top: -130px;
        position: absolute;
        width: 100%;
    }

    .image {
        margin-top: -340px;
        position: absolute;
        right: 30px;

        img {
            box-shadow: 0 3px 6px 0 $color-shadow-album;
        }
    }
}

.player-onair {
    background-color: $color-custom-article;
    box-shadow: 0 4px 6px $color-shadow-top-menu;
    color: $color-light-gray-a;
    float: right;
    height: 32px;
    margin-right: 15px; // Bug fix
    width: 370px;

    .col-6 {
        padding-top: 7px;

        &:first-child {
            font-weight: $font-weight-bold;
            padding-left: 30px;
        }
    }

    .air {
        background-color: $color-dark-gray-a;
        color: $color-white;
        height: 32px;
    }

    i {
        font-size: 1.3em;
        margin-top: -2px;
        vertical-align: middle;
    }
}

.player-news {
    padding: 40px;

    .news-box {
        .col-4 {
            border: 0;
        }
    }
}

.player-pub {
    padding: 40px;
    padding-top: 0;
}

@media (max-width: 1200px) {
    .player-menu {
        text-align: right;
    }

    .player-sidebar {
        height: 200px;
        min-height: auto;
    }

    .player-playing {
        span {
            font-size: 2em !important;
        }
    }
}

// -----------------------------------------------------------------------------

// Weather

.weather {
    box-shadow: 0 3px 6px 0 $color-shadow-top-menu;
    height: 97px;
    margin-bottom: 12px;

    .weatherwidget-io {
        width: 255px;
    }
}

// -----------------------------------------------------------------------------

// Pico TV Banner (EDIT: SIGA-NOS)

.tvbanner {
    background-color: $color-dark-blue-b;
    box-shadow: 0 3px 6px 0 $color-shadow-tvbanner;
    color: $color-white;
    font-size: 1.1em;
    height: 380px;
    padding: 70px 0 10px 55px;

    h1,
    span,
    a {
        text-shadow: 0 2px 4px $color-shadow-top-menu;
    }

    h1 {
        font-size: 1.5em;
        font-weight: $font-weight-extrabold;
        margin-bottom: 55px;
        padding-right: 55px;
        text-align: center;
        text-transform: uppercase;
    }

    a {
        color: $color-light-gray-a;
    }

    div {
        margin-bottom: 16px;
    }

    i {
        color: $color-blue-footer;
        font-size: 2em;
        margin-right: 10px;
        margin-top: -2px;
        vertical-align: middle;
    }

    /*a {
        background: linear-gradient(to bottom, $color-custom-blue 0%, $color-custom-blue-darker 100%);
        box-shadow: 0 2px 4px 0 $color-shadow-tvbutton;
        color: $color-white;
        display: inline-block;
        font-size: .9em;
        font-weight: $font-weight-bold;
        padding: 18px 62px;
        text-shadow: 0 1px 2px $color-shadow-top-menu;

        &:hover {
            background: linear-gradient(to bottom, $color-custom-blue-hover 0%, $color-custom-blue-darker-hover 100%);
            text-decoration: none;
        }
    }*/
}

// -----------------------------------------------------------------------------

// Pagination

.pagination {
    margin-bottom: 40px;

    li {
        margin-right: 10px;

        a {
            border: 1px solid $color-light-blue-b;
            box-shadow: 0 2px 4px 0 $color-shadow-sidebar;
            color: $color-light-blue-b;
            font-weight: $font-weight-bold;
            padding: 5px 7px;

            &:hover {
                background-color: $color-light;
                border: 1px solid $color-light-blue-a;
                box-shadow: 0 2px 4px 0 $color-shadow-image-hover;
                color: $color-light-blue-a;
            }
        }

        &:first-child {
            margin-right: 20px;
        }

        &:last-child {
            margin-left: 10px;
        }
    }

    .page-item {
        &:last-child {
            .page-link {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &:first-child {
            .page-link {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }

    .active,
    .disabled {
        .page-link {
            background-color: $color-white;
            border: 1px solid $color-light-gray-b;
            box-shadow: none;
            color: $color-light-gray-b;
        }
    }
}

// -----------------------------------------------------------------------------

// Publicidade

.pub { // Don't name it "ad" to avoid blocking
    text-align: center;
}

.pub,
.pubwide {
    img {
        border: 1px solid $color-custom-defaul-gray;
    }
}

.pub-article {
    padding-left: 10px;
}

.pubwide {
    margin-top: 60px;
}

.ppm {
    background: $color-white;
    height: 100vh;
    padding: 20px;
    padding-top: 0;
    position: fixed;
    width: 100%;
    z-index: 20;

    .logo {
        margin-bottom: 20px;
    }

    .close {
        cursor: pointer;
        font-size: 1.5em;
        position: absolute;
        right: 20px;
        top: 27px;

        i {
            font-size: 1.2em;
            margin-bottom: -1px;
            vertical-align: bottom;
        }
    }

    .ovf {
        overflow: auto;
    }
}

.iim {
    background: $color-white;
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 20;

    .icon {
        margin-bottom: 10px;
        margin-top: 10px;
        width: 55px;
    }

    .meta {
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 1.5em;
        color: $color-dark-blue-a;
    }

    .close {
        cursor: pointer;
        font-size: 2em;
        position: absolute;
        right: 20px;
        top: 0;

        i {
            font-size: 1.2em;
            margin-bottom: -1px;
            vertical-align: bottom;
        }
    }

    .install-btn {
        position: absolute;
        right: 30px;
        bottom:10px;
        font-size: 1.5em;

        .badge {
            background-color: $color-dark-blue-a;
        }
    }

    .ovf {
        overflow: auto;
    }

    .smart-banner {
        width: 100%;
    }
}
// -----------------------------------------------------------------------------

// App Intro
.app-intro {
    margin-top: 100px;
    h1 {
        color:$color-dark-blue-a;
    }
    p {
        color:$color-dark-gray-b;
        font-size:1.2em;
    }
}
.google_play_btn {
    height: 48px;
    padding-left: 18px;

}
.apple_store_btn {
    height: 48px;
    padding-left: 15px;
}

.mobile_showcase {
    max-height: 69vh;
}
// -----------------------------------------------------------------------------

// Footer

footer {
    background-color: $color-dark-blue-a;
    color: $color-light-gray-a;
    height: 470px;
    margin-top: 25px;

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        margin-top: 8px;
    }

    a {
        color: $color-light-gray-a;
    }

    .group {
        float: left;
        margin-left: 60px;
        padding-bottom: 30px;
        padding-top: 30px;

        h1 {
            color: $color-blue-footer;
            font-size: 1em;
            font-weight: $font-weight-bold;
        }
    }

    .rt {
        margin-top: 30px;
        position: absolute;
        right: 60px;

        h1 {
            color: $color-light-blue-b;
            font-size: 2.5em;
            font-weight: $font-weight-normal;
        }

        .stations {
            display: inline-block;
            margin: 0 auto;
            padding-left: 40px;

            h1 {
                color: $color-blue-footer;
                font-size: 3em;
                font-weight: $font-weight-extrabold;
            }

            div {
                float: left;
                margin-left: 25px;
            }
        }

        .social {
            font-size: 2em;

            a {
                margin-left: 10px;
            }
        }

        .copy {
            margin-top: 20px;
            text-align: center;
            text-shadow: 0 1px 2px $color-shadow-top-menu;
        }
    }
    .app_store_footer_btn {
        height: 25px;
        margin-bottom: 5px;
    }
}

@media (max-width: 1200px) {
    footer {
        .rt {
            right: 0;
            width: 100%;

            h1 {
                text-align: center;
            }

            .stations {
                padding-left: 28px;
                text-align: center;

                h1 {
                    font-size: 2em;
                }
            }

            .social {
                text-align: center;
            }

            .copy {
                margin: 0 auto;
                margin-top: 10px;
                width: 70%;
            }
        }
    }
}

@media (min-width: 1200px) {
    footer {
        .rt {
            text-align: right;

            .copy {
                text-align: right;
            }
        }
    }
}

// -----------------------------------------------------------------------------

// Custom elements

.ekko-lightbox-nav-overlay {
    a {
        color: $color-white;
    }
}

.lg-outer {
    .lg-thumb-item {
        &.active {
            border-color: $color-light-blue-a;
        }

        &:hover {
            border-color: $color-blue-footer;
        }
    }
}

@media (max-width: 1200px) {
    .propgest {
        tr {
            display: table-cell;

            > * {
                display: block;
            }
        }
    }
}

// -----------------------------------------------------------------------------

// Pricing Table - Publicite Aqui

.pricing {
    border: 1px solid $color-light-gray-a;
    box-shadow: 0 2px 4px $color-shadow-table;

    &:hover {
        border: 1px solid $color-light-blue-a;
        box-shadow: 0 2px 4px $color-shadow-input;
    }

    td {
        border: 0;

        &.info {
            text-transform: uppercase;
            vertical-align: middle;

            b {
                color: $color-light-blue-b;
                display: inline-block;
                font-size: 1.9em;
                margin-right: 10px;
            }

            div {
                border-bottom: 1px solid $color-light-gray-a;

                &:hover {
                    border-bottom: 1px solid $color-light-blue-a;
                }

                &.active {
                    border-bottom: 1px solid $color-light-blue-a;
                }
            }

            label {
                color: $color-light-blue-a;
                cursor: pointer;
                display: block;
                font-weight: $font-weight-normal;
            }

            input {
                float: right;
                margin-right: 15px;
                margin-top: 10px;
            }
        }

        &.t-title {
            color: $color-light-gray-b;
            font-size: 1.1em;

            b {
                color: $color-light-blue-a;
                display: block;
                font-size: 1.8em;
                margin-bottom: 10px;
            }
        }
    }

    .rep {
        //border: 1px solid $color-light-gray-b;
        color: $color-light-blue-b;
        height: 184px;
        line-height: 2.2em;
        width: 100%;

        img {
            height: 100%;
        }

        /*div {
            border: 2px solid $color-light-blue-b;
            margin: 10px;
        }*/
    }

    .rep-mobile {
        div {
            height: 85%;
            margin-top: 14px;
            padding-top: 24px;
            text-align: center;
        }
    }

    /*.rep-vert {
        padding-left: 10px;
        padding-top: 35px;
        text-align: left;

        div {
            height: 60px;
            margin-left: 70px;
            margin-top: -80px;
            position: absolute;
            width: 50px;
        }
    }

    .rep-hor {
        padding-top: 20px;
        text-align: center;

        div {
            height: 15%;
            margin-top: 22px;
        }
    }*/
}

.pub-here {
    .title {
        color: $color-dark-blue-a;
        font-family: $font-family-grad;
        font-size: 3.1em;
        font-weight: $font-weight-bold;
        margin-bottom: 20px;
    }

    .desc {
        color: $color-light-blue-b;
        font-size: 1.1em;
        margin-bottom: 30px;
    }

    h2 {
        color: $color-light-gray-b;
        font-family: $font-family-grad;
        margin-bottom: 25px;
    }
}

// -----------------------------------------------------------------------------

// Forms

form {
    input,
    textarea {
        border-radius: 0 !important;
        box-shadow: 0 2px 4px $color-light-gray-a;
        font-size: 1.1em !important;
        padding: 14px !important;

        &[type='text'],
        &[type='email'] {
            border: 1px solid $color-light-gray-a;

            &:hover {
                box-shadow: 0 2px 4px $color-shadow-input;
            }

            &:focus {
                border: 1px solid $color-light-blue-a;
                box-shadow: 0 2px 4px $color-shadow-input;
                outline: none;
            }
        }

        &::-webkit-input-placeholder {
            color: $color-light-gray-b !important;
        }

        &::-moz-placeholder {
            color: $color-light-gray-b !important;
        }

        &:-ms-input-placeholder {
            color: $color-light-gray-b !important;
        }
    }

    textarea {
        border: 1px solid $color-light-gray-a;

        &:hover {
            box-shadow: 0 2px 4px $color-shadow-input;
        }

        &:focus {
            border: 1px solid $color-light-blue-a;
            box-shadow: 0 2px 4px $color-shadow-input;
            outline: none;
        }
    }

    button {
        &[type='submit'],
        &.submit {
            background: $color-light-blue-a;
            border-radius: 0;
            box-shadow: 0 2px 4px $color-light-blue-a-hover;
            font-size: 1.1em;
            font-weight: bold;
            padding: 15px 22px;
            text-shadow: 0 1px 2px $color-custom-date-featured;
        }
    }

    label {
        color: $color-dark-gray-a;
        font-size: 1.1em;
        font-weight: $font-weight-bold;

        &.req {
            &::after {
                color: $color-light-blue-b;
                content: ' (obrigatório)';
                font-size: .7em;
                font-weight: $font-weight-normal;
            }
        }
    }

    .text-muted {
        color: $color-light-gray-b !important;
    }
}

@media only screen and (max-width: 767px) {
    .banner-horizontal{
        padding-left:0;
        padding-right:0;
    }
}
// -----------------------------------------------------------------------------

// Print

@media print {
    .menu-top,
    .sidebar,
    .article-side,
    .info,
    .social,
    .rt,
    .news-box-title,
    .news-box,
    .pubwide,
    .playing,
    .publicite-aqui,
    iframe,
    footer {
        display: none !important;
    }
}
