@charset "UTF-8";
/*! (c) Lavaimagem - https://www.lavaimagem.com/ */
body {
  font-family: "proxima-nova", sans-serif;
  font-size: 12px; }

* {
  -moz-margin-end: 0;
  -moz-margin-start: 0;
  -moz-padding-end: 0;
  -moz-padding-start: 0;
  -webkit-margin-after: 0;
  -webkit-margin-before: 0;
  -webkit-margin-end: 0;
  -webkit-margin-start: 0;
  -webkit-padding-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  padding-inline-end: 0;
  padding-inline-start: 0; }

a {
  color: #3c79db; }
  a:hover {
    color: #7280ad; }

.clear {
  clear: both; }

p {
  display: inline-block;
  margin: 0;
  padding: 0; }

.right {
  float: right; }

h2 {
  color: #7280ad;
  font-size: 1.8em;
  font-weight: 800; }

font {
  font-family: "proxima-nova", sans-serif;
  font-size: 15px; }

.imgbox {
  background-position: center center;
  background-repeat: no-repeat; }

.spacer {
  display: block;
  height: 30px; }

.magnified {
  font-size: 1.2em; }

::-webkit-scrollbar {
  width: 7px; }

::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25); }

::-webkit-scrollbar-thumb {
  background: #6e6e6e;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25); }

.modal-body {
  font-size: 1.3em; }

.btn-subscribe i,
.btn-unsubscribe i {
  font-size: 1.2em;
  margin-top: -3px;
  vertical-align: middle; }

.btn-unsubscribe {
  display: none; }

.logo {
  padding-top: 15px; }

.publicite-aqui {
  border: 1px solid #bfc7ce;
  font-weight: 700;
  height: 55px;
  padding: 19px;
  position: absolute;
  right: 30px;
  text-transform: uppercase;
  top: 28px; }
  .publicite-aqui a {
    color: #bfc7ce; }
    .publicite-aqui a:hover {
      color: #777c8b;
      text-decoration: none; }

.menu-top {
  background-color: #2c3a67;
  color: #fff;
  height: 55px;
  margin-bottom: 30px;
  margin-top: 15px; }
  .menu-top .menu-item-group ul {
    list-style-type: none;
    padding: 0; }
  .menu-top .menu-item-group li {
    border-bottom: 4px solid #2c3a67;
    display: inline-block;
    height: 55px;
    margin-right: 30px;
    padding: 0 25px;
    padding-top: 18px;
    text-transform: uppercase; }
  .menu-top .menu-item-group a {
    color: #7280ad;
    font-weight: 700;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25); }
    .menu-top .menu-item-group a:hover {
      color: #dfe6eb; }
    .menu-top .menu-item-group a.active {
      color: #dfe6eb; }
      .menu-top .menu-item-group a.active li {
        border-bottom: 4px solid #dfe6eb; }
  .menu-top .left-menu {
    left: 0;
    position: absolute; }
  .menu-top .right-menu {
    position: absolute;
    right: 0; }
    .menu-top .right-menu div {
      float: left; }
    .menu-top .right-menu .tv {
      font-weight: 700;
      margin-right: 50px;
      padding-top: 19px;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25); }
      .menu-top .right-menu .tv a {
        color: #fff; }
    .menu-top .right-menu .notif {
      cursor: pointer;
      margin-right: 30px;
      padding-top: 19px;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25); }
      .menu-top .right-menu .notif i {
        font-size: 2.2em;
        margin-top: -5px;
        vertical-align: middle; }
    .menu-top .right-menu .search,
    .menu-top .right-menu .sidebar-toggle {
      cursor: pointer;
      height: 55px;
      padding-right: 10px;
      padding-top: 12px; }
      .menu-top .right-menu .search i,
      .menu-top .right-menu .sidebar-toggle i {
        font-size: 2.6em; }
      .menu-top .right-menu .search .menuclose,
      .menu-top .right-menu .sidebar-toggle .menuclose {
        display: none; }
    .menu-top .right-menu .search i {
      position: absolute;
      right: 80px; }
    .menu-top .right-menu .search input {
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid #fff;
      box-shadow: none;
      color: #fff;
      height: 30px;
      opacity: 0;
      outline: none;
      padding: 10px;
      transition: width .6s, opacity .6s;
      width: 0;
      z-index: 2; }
      .menu-top .right-menu .search input:focus {
        opacity: 1;
        outline: none;
        width: 200px; }
      .menu-top .right-menu .search input::-webkit-input-placeholder {
        color: #bfc7ce; }
      .menu-top .right-menu .search input::-moz-placeholder {
        color: #bfc7ce; }
      .menu-top .right-menu .search input:-ms-input-placeholder {
        color: #bfc7ce; }
    .menu-top .right-menu .sidebar-toggle,
    .menu-top .right-menu .open {
      margin-right: 10px;
      padding-left: 15px;
      padding-right: 15px; }
    .menu-top .right-menu .open {
      background: #fff;
      color: #2c3a67; }
      .menu-top .right-menu .open .menuopen {
        display: none; }
      .menu-top .right-menu .open .menuclose {
        display: inline-block; }
  .menu-top .play-btn {
    color: #fff; }

.sidebar {
  background: #fff;
  border-bottom: 1px solid #bfc7ce;
  border-left: 1px solid #bfc7ce;
  box-shadow: 0 0 6px 0 rgba(114, 128, 173, 0.25);
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 20px;
  position: fixed;
  right: -330px;
  top: 0;
  transition: .5s;
  width: 330px;
  z-index: 15; }
  .sidebar .group {
    padding: 0 20px;
    padding-top: 20px;
    width: 50%; }
  .sidebar ul {
    list-style-type: none;
    padding: 0; }
  .sidebar li {
    margin-top: 12px; }
    .sidebar li a {
      color: #7280ad; }
      .sidebar li a:hover {
        color: #3c79db;
        text-decoration: none; }
      .sidebar li a.active {
        color: #3c79db; }
  .sidebar h1 {
    color: #777c8b;
    font-size: 1.1em;
    font-weight: 700;
    margin: 0; }
  .sidebar .mobile {
    text-align: center;
    width: 100%; }
    .sidebar .mobile span {
      color: #bfc7ce;
      font-weight: 800; }
    .sidebar .mobile a {
      color: #7280ad;
      font-weight: 800;
      text-transform: uppercase; }
      .sidebar .mobile a li {
        border-bottom: 1px solid #dfe6eb;
        padding-bottom: 18px;
        padding-top: 8px; }
      .sidebar .mobile a:last-child li {
        border: 0;
        box-shadow: inset 0 -10px 6px -10px rgba(114, 128, 173, 0.25); }

.sidebar-highlighter {
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  margin-left: -15px;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  transition: opacity .3s linear;
  width: 100%;
  z-index: 10; }
  .sidebar-highlighter.visible {
    opacity: 1;
    pointer-events: auto; }

.menu-top-fixed {
  margin-left: 0;
  margin-top: 0;
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 5; }

.menu-top-fixed-spacer {
  margin-bottom: 100px; }

.logo,
.menu-top,
.container,
footer {
  left: 0;
  position: relative;
  transition: left .5s; }

.catnav ul {
  list-style-type: none;
  padding: 0; }

.catnav a {
  color: #7280ad;
  text-transform: uppercase; }
  .catnav a:hover {
    border-bottom: 1px solid #3c79db;
    color: #3c79db;
    text-decoration: none; }

.catnav li {
  display: inline-block;
  margin-right: 20px; }

.catnav .active a {
  border-bottom: 1px solid #3c79db;
  color: #3c79db; }
  .catnav .active a:hover {
    color: #7280ad; }

@media (max-width: 991px) {
  .catnav {
    margin-bottom: 0;
    margin-top: 40px;
    width: 100%; }
    .catnav ul {
      display: block; } }

.featured-box {
  border: 1px solid #bfc7ce;
  box-shadow: 0 6px 12px 0 rgba(114, 128, 173, 0.25);
  cursor: pointer; }
  .featured-box .title,
  .featured-box .article {
    display: block; }
  .featured-box .title {
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    color: #2c3a67;
    font-family: "grad", serif;
    font-size: 1.5em;
    font-weight: 700;
    padding: 16px 10px; }
  .featured-box .article {
    color: #777c8b;
    font-family: "grad", serif;
    height: 95px;
    padding: 0 15px;
    padding-bottom: 10px; }
  .featured-box .date {
    color: rgba(44, 58, 103, 0.5);
    display: block;
    font-size: .8em;
    font-weight: bold;
    height: 24px;
    padding-right: 10px;
    text-align: right; }
  .featured-box:hover {
    background-color: #fdfeff;
    border: 1px solid #2c3a67;
    box-shadow: 0 6px 12px 0 rgba(60, 121, 219, 0.25); }
    .featured-box:hover .article {
      color: #2c3a67; }

@media (min-width: 768px) {
  .fifty {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (max-width: 991.98px) {
  .fifty {
    padding: 0; }
  .fifty-articles article .row {
    margin-right: 0; } }

.article-box {
  border-bottom: 1px solid #dfe6eb;
  cursor: pointer;
  margin-bottom: 26px;
  padding-bottom: 26px; }
  .article-box:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0; }
  .article-box .col-lg-4 {
    padding-right: 0; }
  .article-box .img {
    border: 1px solid #bfc7ce;
    box-shadow: 0 2px 6px 0 rgba(114, 128, 173, 0.25);
    height: 110px;
    width: 100%; }
  .article-box .title {
    color: #7280ad;
    display: block;
    font-family: "grad", serif;
    font-size: 1.3em;
    font-weight: 700;
    margin-bottom: 5px;
    padding-top: 8px; }
  .article-box .article {
    color: #777c8b;
    font-family: "grad", serif;
    font-size: 1.05em; }
  .article-box .date {
    color: rgba(44, 58, 103, 0.5);
    display: block;
    float: right;
    font-size: .8em;
    font-weight: bold;
    margin-top: 5px;
    text-align: right; }
  .article-box:hover {
    border-bottom: 1px solid #2c3a67; }
    .article-box:hover img {
      border: 1px solid #2c3a67;
      box-shadow: 0 2px 6px 0 rgba(60, 121, 219, 0.25); }
    .article-box:hover .article,
    .article-box:hover .title {
      color: #2c3a67; }
    .article-box:hover:last-child {
      border-bottom: 0; }

.compressed-article .article-box img {
  height: 100px;
  width: 110px; }

@media (max-width: 991px) {
  .article-box,
  .videos-box {
    margin-top: 40px; }
    .article-box .img,
    .videos-box .img {
      background-size: cover; } }

.popular-box .header,
.popular-box article {
  border: 1px solid #dfe6eb;
  border-bottom: 0;
  text-align: center; }

.popular-box .header {
  color: #777c8b;
  font-size: 1.1em;
  font-weight: 700;
  padding: 6px 0; }

.popular-box article {
  color: #7280ad;
  cursor: pointer;
  font-family: "grad", serif;
  font-weight: 700;
  height: 95px;
  padding: 0 25px; }
  .popular-box article:last-child {
    border-bottom: 1px solid #dfe6eb; }
    .popular-box article:last-child:hover {
      border-bottom: 1px solid #3c79db; }
  .popular-box article:hover {
    background: #fdfeff;
    border: 1px solid #3c79db;
    border-bottom: 0;
    box-shadow: 0 2px 4px 0 rgba(60, 121, 219, 0.25);
    color: #3c79db; }
  .popular-box article:hover + article {
    border-top: 1px solid #3c79db; }

.popular-box .title {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.newsbtn {
  width: 100%; }
  .newsbtn span {
    background: #fdfeff;
    border: 1px solid #3c79db;
    box-shadow: 0 2px 4px 0 rgba(60, 121, 219, 0.25);
    color: #3c79db;
    cursor: pointer;
    display: block;
    font-weight: 800;
    margin-top: 30px;
    padding: 20px;
    text-align: center;
    width: 100%; }

.videos-box {
  border: 1px solid #dfe6eb; }
  .videos-box .header {
    border-bottom: 1px solid #dfe6eb;
    color: #777c8b;
    font-size: 1.1em;
    font-weight: 700;
    padding: 9px 14px; }
  .videos-box span {
    float: right;
    font-size: .9em;
    text-transform: uppercase; }
    .videos-box span i {
      font-size: 1.4em;
      font-weight: 700;
      vertical-align: middle; }
  .videos-box .row {
    padding: 57px 14px;
    padding-bottom: 25px; }
  .videos-box .video {
    cursor: pointer;
    margin-bottom: 30px; }
    .videos-box .video:hover img {
      border: 1px solid #3c79db;
      box-shadow: 0 2px 4px 0 rgba(60, 121, 219, 0.25); }
    .videos-box .video:hover .title {
      color: #3c79db; }
  .videos-box .image {
    float: left;
    width: 50%; }
    .videos-box .image img {
      border: 1px solid #2c3a67;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16); }
  .videos-box .title {
    color: #7280ad;
    float: left;
    padding-top: 5px;
    width: 50%; }

.news-box-title .content {
  border-bottom: 1px solid #dfe6eb;
  color: #6e6e6e;
  font-size: 1.1em;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 25px;
  padding-bottom: 10px; }
  .news-box-title .content span {
    float: right;
    text-transform: uppercase; }
    .news-box-title .content span i {
      font-size: 1.4em;
      font-weight: 700;
      vertical-align: middle; }

.news-box {
  padding-bottom: 10px; }
  .news-box .image,
  .news-box .title {
    float: left; }
  .news-box .image {
    border: 1px solid #bfc7ce;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 20px; }
    .news-box .image .img {
      height: 61px;
      width: 68px; }
  .news-box .title {
    color: #777c8b;
    font-family: "grad", serif;
    font-size: 1em;
    font-weight: 700;
    height: 63px;
    margin-left: 10px;
    width: 70%; }
    .news-box .title span:first-child {
      color: rgba(44, 58, 103, 0.5);
      display: block;
      font-family: "proxima-nova", sans-serif;
      font-size: .9em;
      font-weight: 300;
      padding-top: 8px; }
    .news-box .title span:last-child {
      float: left; }
    .news-box .title span.date {
      font-family: "proxima-nova", sans-serif;
      font-size: .8em;
      font-weight: 700; }
    .news-box .title p,
    .news-box .title p * {
      color: #000;
      display: block;
      font-family: "proxima-nova", sans-serif;
      font-weight: 500; }
  .news-box .col-4 {
    border-right: 1px solid #dfe6eb; }
    .news-box .col-4:last-child {
      border-right: 0; }
  .news-box .group {
    cursor: pointer; }
    .news-box .group:hover .image {
      border: 1px solid #3c79db;
      box-shadow: 0 2px 4px 0 rgba(60, 121, 219, 0.25); }
    .news-box .group:hover .title {
      color: #3c79db; }

.news-box-line {
  border-bottom: 1px solid #dfe6eb; }

.extra {
  margin-bottom: 35px;
  margin-top: 50px; }

.videos-list {
  margin-top: 40px;
  padding-bottom: 10px; }
  .videos-list .image,
  .videos-list .title {
    float: left; }
  .videos-list .image {
    margin-bottom: 40px;
    width: 45%; }
    .videos-list .image img {
      border: 1px solid #2c3a67;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16); }
  .videos-list .title {
    color: #7280ad;
    height: 63px;
    margin-left: 10px;
    width: 45%;
    word-wrap: break-word; }
    .videos-list .title span {
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
  .videos-list .group {
    cursor: pointer; }
    .videos-list .group:hover img {
      border: 1px solid #3c79db;
      box-shadow: 0 2px 4px 0 rgba(60, 121, 219, 0.25); }
    .videos-list .group:hover .title {
      color: #3c79db; }

@media (max-width: 1199px) {
  .videos-list .title {
    width: auto; }
  .newsbtn {
    margin-left: 0;
    margin-right: 0; } }

.article .col-lg-8 {
  padding-right: 0; }

.article .col-4 {
  border-left: 1px solid #dfe6eb;
  padding-left: 0; }

.article .title {
  color: #2c3a67;
  font-family: "grad", serif;
  font-size: 3.3em;
  font-weight: 700;
  text-align: center; }

.article .articletitle {
  line-height: 1.3em;
  padding: 5px 145px 30px; }
  .article .articletitle h4 {
    color: #777c8b;
    font-family: "proxima-nova", sans-serif;
    font-size: .4em;
    margin: 0;
    margin-top: 5px; }

.article .header {
  border-bottom: 1px solid #dfe6eb;
  padding: 10px; }
  .article .header .info {
    color: #bfc7ce;
    font-weight: 700;
    text-transform: uppercase; }
    .article .header .info i {
      font-size: 1.1em;
      font-weight: 700;
      margin-right: 5px;
      margin-top: -4px;
      vertical-align: middle; }
  .article .header .social {
    color: #3c79db;
    float: right; }
    .article .header .social div {
      float: left;
      margin-left: 2px; }
      .article .header .social div .button-wpp {
        background-color: #25d366;
        color: #eee;
        border-radius: 3px;
        font-size: 11px;
        height: 20px;
        padding: 0 6px; }
      .article .header .social div .button-wpp:hover {
        background-color: #21bd5c; }
      .article .header .social div .button-wpp:focus {
        background-color: #1a9247; }

.article .view {
  border-bottom: 1px solid #dfe6eb;
  color: #7280ad;
  font-weight: 700;
  padding: 7px; }
  .article .view i {
    font-weight: 500;
    vertical-align: middle; }
  .article .view span {
    float: right; }
  .article .view a {
    color: #7280ad; }
    .article .view a:hover {
      color: #3c79db;
      text-decoration: none; }

.article .content {
  color: #404b57;
  margin-bottom: 30px;
  padding: 30px; }
  .article .content img {
    cursor: pointer;
    height: auto !important;
    max-width: 100%; }
  .article .content .image {
    margin: 30px;
    text-align: center; }
    .article .content .image img {
      border: 1px solid #bfc7ce;
      box-shadow: 0 2px 6px 0 rgba(44, 58, 103, 0.25); }
  .article .content p {
    display: block;
    font-family: "grad", serif;
    margin: 20px 0; }
  .article .content table {
    border: 1px solid #777c8b;
    margin: 10px 0; }
    .article .content table th,
    .article .content table td {
      padding: 5px 10px; }
  .article .content ul li,
  .article .content ol li {
    margin-left: 13px; }
  .article .content blockquote {
    color: #7280ad; }
    .article .content blockquote::before {
      color: #dfe6eb;
      content: '>> ';
      margin-right: 10px; }

.article .gallery-brief {
  height: 80px;
  margin-top: 60px;
  overflow: hidden;
  padding: 10px;
  padding-top: 0;
  text-align: center; }
  .article .gallery-brief img {
    border: 1px solid #bfc7ce;
    height: 60px !important;
    margin-right: 10px;
    margin-top: 10px; }
    .article .gallery-brief img:first-child {
      margin-left: 10px; }
    .article .gallery-brief img:hover {
      border: 1px solid #3c79db;
      box-shadow: 0 2px 4px 0 rgba(60, 121, 219, 0.25); }
  .article .gallery-brief .arrow {
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    cursor: pointer;
    height: 0;
    margin-top: 30px;
    position: absolute;
    width: 0; }
  .article .gallery-brief .arrow-left {
    border-right: 10px solid #3c79db; }
    .article .gallery-brief .arrow-left.disabled {
      border-right: 10px solid #6e6e6e; }
  .article .gallery-brief .arrow-right {
    border-left: 10px solid #3c79db;
    margin-right: 40px;
    right: 0; }
    .article .gallery-brief .arrow-right.disabled {
      border-left: 10px solid #6e6e6e; }

.article .related {
  padding: 30px;
  padding-top: 10px; }
  .article .related .header {
    color: #777c8b;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center; }
  .article .related .image {
    float: left;
    margin-right: 10px;
    width: 50px; }
    .article .related .image img {
      border: 1px solid #bfc7ce;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16); }
  .article .related .title {
    color: #777c8b;
    float: left;
    font-size: 1em;
    padding-top: 10px; }
  .article .related article {
    margin-bottom: 20px; }
    .article .related article:hover {
      cursor: pointer; }
      .article .related article:hover .image img {
        border: 1px solid #3c79db;
        box-shadow: 0 2px 4px 0 rgba(60, 121, 219, 0.25); }
      .article .related article:hover .title {
        color: #3c79db; }

.article-box-title {
  margin-top: 20px; }
  .article-box-title .col-4 {
    padding: 0; }

@media (max-width: 1200px) {
  .article .col-lg-8 {
    padding-right: 15px; }
  .article .content {
    padding-left: 0;
    padding-right: 0; }
    .article .content .image {
      margin: 0;
      margin-right: 10px;
      width: 100%; }
  .article .title {
    font-size: 2em;
    padding: 5px 15px 40px; } }

.static-content h2 {
  color: #2c3a67;
  font-family: "grad", serif;
  font-size: 2.8em;
  font-weight: 700; }

.static-content .content {
  color: #404b57;
  font-size: 1.3em; }
  .static-content .content p {
    display: block;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    margin-top: 1em; }
  .static-content .content img {
    margin: 20px 0; }

.content-step {
  margin-bottom: 30px; }

@media (max-width: 1200px) {
  .static-content .content img {
    width: 100%; } }

.playing {
  color: #3c79db;
  font-size: .9em;
  left: 10px;
  position: absolute;
  top: 88px; }
  .playing b {
    font-weight: 800;
    margin-right: 5px; }

.player {
  background-color: #3c79db;
  box-shadow: 0 2px 4px 0 rgba(64, 75, 87, 0.15);
  cursor: pointer;
  float: left;
  font-weight: 700;
  height: 55px;
  padding: 0 10px;
  padding-top: 18px;
  text-shadow: 0 1px 2px rgba(44, 58, 103, 0.7); }
  .player:hover {
    background-color: #5288df; }
  .player i {
    font-size: 2.3em;
    margin-top: -5px;
    vertical-align: middle; }

.onair {
  float: left; }
  .onair div {
    float: left; }
  .onair .image {
    padding-left: 10px;
    padding-top: 8px; }
  .onair .person {
    padding-left: 7px;
    padding-top: 18px; }
    .onair .person i {
      font-size: 1.4em;
      margin-top: -3px;
      vertical-align: middle; }

.player-logo {
  background: #fff;
  box-shadow: 0 3px 12px 0 rgba(64, 75, 87, 0.15);
  height: 50px;
  padding-top: 6px;
  z-index: 999; }
  .player-logo span {
    color: #2c3a67;
    font-size: .9em;
    font-weight: 700;
    margin-left: 8px;
    position: absolute;
    top: 18px; }

.player-menu {
  box-shadow: 0 3px 12px 0 rgba(64, 75, 87, 0.15);
  height: 50px;
  padding-top: 16px; }
  .player-menu ul {
    display: inline;
    list-style-type: none;
    margin-left: 20px;
    padding: 0; }
  .player-menu li {
    display: inline-block;
    margin-right: 25px; }
  .player-menu a {
    color: #bfc7ce;
    font-size: .9em;
    font-weight: 700;
    text-transform: uppercase; }
    .player-menu a:hover {
      color: #3c79db;
      text-decoration: none; }
    .player-menu a.active {
      color: #3c79db; }
      .player-menu a.active:hover {
        border-bottom: 1px solid #3c79db; }
  .player-menu .social {
    float: right; }
    .player-menu .social div {
      float: left;
      margin-left: 4px; }

.player-sidebar {
  background-color: #323748;
  box-shadow: inset 0 0 30px rgba(64, 75, 87, 0.15);
  color: #fff;
  height: calc(100% - 50px);
  max-height: 120vh;
  min-height: 100vh;
  overflow-y: auto;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25); }
  .player-sidebar h3 {
    font-size: 1em;
    margin-top: 30px;
    text-align: center; }
    .player-sidebar h3 i {
      vertical-align: middle; }
  .player-sidebar ul {
    list-style-type: none;
    margin-left: 10px;
    padding: 0; }
  .player-sidebar li {
    color: #7280ad;
    margin-top: 15px; }

.player-content.col-lg-9 {
  padding-left: 0;
  padding-right: 0; }

.player-content font {
  font-size: 1em; }

.player-media {
  color: #fff;
  height: 400px;
  overflow: hidden;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.58); }
  .player-media .player-playing {
    line-height: 3em;
    margin-left: 60px;
    margin-top: -340px;
    max-width: 630px;
    position: absolute; }
    .player-media .player-playing h3 {
      font-size: 1em;
      font-weight: 500; }
    .player-media .player-playing span {
      font-size: 3em;
      font-weight: 700; }
  .player-media .player-next {
    line-height: 2em;
    margin-left: 60px;
    margin-top: -230px;
    position: absolute; }
    .player-media .player-next h3 {
      font-size: 1em;
      font-weight: 500; }
    .player-media .player-next span {
      font-size: 1.1em;
      font-weight: 700; }
  .player-media .controls {
    margin-left: 60px;
    margin-top: -170px;
    position: absolute;
    width: 200px; }
    .player-media .controls .slider {
      margin-top: -8px;
      width: 90px; }
      .player-media .controls .slider .slider-selection {
        background: #3c79db;
        border-radius: 0;
        height: 4px; }
      .player-media .controls .slider .slider-track {
        height: 4px; }
      .player-media .controls .slider .slider-track-high {
        background: #dfe6eb;
        border-radius: 0;
        height: 4px; }
      .player-media .controls .slider .slider-handle {
        background: #fff;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.58);
        height: 9px;
        margin-left: -3px;
        margin-top: 2px;
        width: 9px; }
    .player-media .controls .player-volume {
      margin-left: 20px;
      margin-top: 10px;
      position: absolute; }
      .player-media .controls .player-volume i {
        font-size: 1.8em;
        margin-right: 10px; }
    .player-media .controls .player-play {
      padding-top: 25px; }
      .player-media .controls .player-play .audio-play i {
        cursor: pointer;
        font-size: 3.3em; }
    .player-media .controls .volume-icon {
      cursor: pointer; }
  .player-media .art {
    background-color: #7280ad;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(18px);
    height: 100%;
    position: relative;
    transform: scale(1.3);
    width: 100%;
    z-index: -1; }
  .player-media .bars {
    height: 130px;
    margin-top: -130px;
    position: absolute;
    width: 100%; }
  .player-media .image {
    margin-top: -340px;
    position: absolute;
    right: 30px; }
    .player-media .image img {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.58); }

.player-onair {
  background-color: #404b57;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
  color: #dfe6eb;
  float: right;
  height: 32px;
  margin-right: 15px;
  width: 370px; }
  .player-onair .col-6 {
    padding-top: 7px; }
    .player-onair .col-6:first-child {
      font-weight: 700;
      padding-left: 30px; }
  .player-onair .air {
    background-color: #777c8b;
    color: #fff;
    height: 32px; }
  .player-onair i {
    font-size: 1.3em;
    margin-top: -2px;
    vertical-align: middle; }

.player-news {
  padding: 40px; }
  .player-news .news-box .col-4 {
    border: 0; }

.player-pub {
  padding: 40px;
  padding-top: 0; }

@media (max-width: 1200px) {
  .player-menu {
    text-align: right; }
  .player-sidebar {
    height: 200px;
    min-height: auto; }
  .player-playing span {
    font-size: 2em !important; } }

.weather {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
  height: 97px;
  margin-bottom: 12px; }
  .weather .weatherwidget-io {
    width: 255px; }

.tvbanner {
  background-color: #323748;
  box-shadow: 0 3px 6px 0 rgba(44, 58, 103, 0.25);
  color: #fff;
  font-size: 1.1em;
  height: 380px;
  padding: 70px 0 10px 55px;
  /*a {
        background: linear-gradient(to bottom, $color-custom-blue 0%, $color-custom-blue-darker 100%);
        box-shadow: 0 2px 4px 0 $color-shadow-tvbutton;
        color: $color-white;
        display: inline-block;
        font-size: .9em;
        font-weight: $font-weight-bold;
        padding: 18px 62px;
        text-shadow: 0 1px 2px $color-shadow-top-menu;

        &:hover {
            background: linear-gradient(to bottom, $color-custom-blue-hover 0%, $color-custom-blue-darker-hover 100%);
            text-decoration: none;
        }
    }*/ }
  .tvbanner h1,
  .tvbanner span,
  .tvbanner a {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25); }
  .tvbanner h1 {
    font-size: 1.5em;
    font-weight: 800;
    margin-bottom: 55px;
    padding-right: 55px;
    text-align: center;
    text-transform: uppercase; }
  .tvbanner a {
    color: #dfe6eb; }
  .tvbanner div {
    margin-bottom: 16px; }
  .tvbanner i {
    color: #77bad6;
    font-size: 2em;
    margin-right: 10px;
    margin-top: -2px;
    vertical-align: middle; }

.pagination {
  margin-bottom: 40px; }
  .pagination li {
    margin-right: 10px; }
    .pagination li a {
      border: 1px solid #7280ad;
      box-shadow: 0 2px 4px 0 rgba(114, 128, 173, 0.25);
      color: #7280ad;
      font-weight: 700;
      padding: 5px 7px; }
      .pagination li a:hover {
        background-color: #fdfeff;
        border: 1px solid #3c79db;
        box-shadow: 0 2px 4px 0 rgba(60, 121, 219, 0.25);
        color: #3c79db; }
    .pagination li:first-child {
      margin-right: 20px; }
    .pagination li:last-child {
      margin-left: 10px; }
  .pagination .page-item:last-child .page-link {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .pagination .page-item:first-child .page-link {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .pagination .active .page-link,
  .pagination .disabled .page-link {
    background-color: #fff;
    border: 1px solid #bfc7ce;
    box-shadow: none;
    color: #bfc7ce; }

.pub {
  text-align: center; }

.pub img,
.pubwide img {
  border: 1px solid #EFEFEF; }

.pub-article {
  padding-left: 10px; }

.pubwide {
  margin-top: 60px; }

.ppm {
  background: #fff;
  height: 100vh;
  padding: 20px;
  padding-top: 0;
  position: fixed;
  width: 100%;
  z-index: 20; }
  .ppm .logo {
    margin-bottom: 20px; }
  .ppm .close {
    cursor: pointer;
    font-size: 1.5em;
    position: absolute;
    right: 20px;
    top: 27px; }
    .ppm .close i {
      font-size: 1.2em;
      margin-bottom: -1px;
      vertical-align: bottom; }
  .ppm .ovf {
    overflow: auto; }

.iim {
  background: #fff;
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 20; }
  .iim .icon {
    margin-bottom: 10px;
    margin-top: 10px;
    width: 55px; }
  .iim .meta {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 1.5em;
    color: #2c3a67; }
  .iim .close {
    cursor: pointer;
    font-size: 2em;
    position: absolute;
    right: 20px;
    top: 0; }
    .iim .close i {
      font-size: 1.2em;
      margin-bottom: -1px;
      vertical-align: bottom; }
  .iim .install-btn {
    position: absolute;
    right: 30px;
    bottom: 10px;
    font-size: 1.5em; }
    .iim .install-btn .badge {
      background-color: #2c3a67; }
  .iim .ovf {
    overflow: auto; }
  .iim .smart-banner {
    width: 100%; }

.app-intro {
  margin-top: 100px; }
  .app-intro h1 {
    color: #2c3a67; }
  .app-intro p {
    color: #6e6e6e;
    font-size: 1.2em; }

.google_play_btn {
  height: 48px;
  padding-left: 18px; }

.apple_store_btn {
  height: 48px;
  padding-left: 15px; }

.mobile_showcase {
  max-height: 69vh; }

footer {
  background-color: #2c3a67;
  color: #dfe6eb;
  height: 470px;
  margin-top: 25px; }
  footer ul {
    list-style-type: none;
    padding: 0; }
  footer li {
    margin-top: 8px; }
  footer a {
    color: #dfe6eb; }
  footer .group {
    float: left;
    margin-left: 60px;
    padding-bottom: 30px;
    padding-top: 30px; }
    footer .group h1 {
      color: #77bad6;
      font-size: 1em;
      font-weight: 700; }
  footer .rt {
    margin-top: 30px;
    position: absolute;
    right: 60px; }
    footer .rt h1 {
      color: #7280ad;
      font-size: 2.5em;
      font-weight: 500; }
    footer .rt .stations {
      display: inline-block;
      margin: 0 auto;
      padding-left: 40px; }
      footer .rt .stations h1 {
        color: #77bad6;
        font-size: 3em;
        font-weight: 800; }
      footer .rt .stations div {
        float: left;
        margin-left: 25px; }
    footer .rt .social {
      font-size: 2em; }
      footer .rt .social a {
        margin-left: 10px; }
    footer .rt .copy {
      margin-top: 20px;
      text-align: center;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25); }
  footer .app_store_footer_btn {
    height: 25px;
    margin-bottom: 5px; }

@media (max-width: 1200px) {
  footer .rt {
    right: 0;
    width: 100%; }
    footer .rt h1 {
      text-align: center; }
    footer .rt .stations {
      padding-left: 28px;
      text-align: center; }
      footer .rt .stations h1 {
        font-size: 2em; }
    footer .rt .social {
      text-align: center; }
    footer .rt .copy {
      margin: 0 auto;
      margin-top: 10px;
      width: 70%; } }

@media (min-width: 1200px) {
  footer .rt {
    text-align: right; }
    footer .rt .copy {
      text-align: right; } }

.ekko-lightbox-nav-overlay a {
  color: #fff; }

.lg-outer .lg-thumb-item.active {
  border-color: #3c79db; }

.lg-outer .lg-thumb-item:hover {
  border-color: #77bad6; }

@media (max-width: 1200px) {
  .propgest tr {
    display: table-cell; }
    .propgest tr > * {
      display: block; } }

.pricing {
  border: 1px solid #dfe6eb;
  box-shadow: 0 2px 4px rgba(223, 230, 235, 0.5);
  /*.rep-vert {
        padding-left: 10px;
        padding-top: 35px;
        text-align: left;

        div {
            height: 60px;
            margin-left: 70px;
            margin-top: -80px;
            position: absolute;
            width: 50px;
        }
    }

    .rep-hor {
        padding-top: 20px;
        text-align: center;

        div {
            height: 15%;
            margin-top: 22px;
        }
    }*/ }
  .pricing:hover {
    border: 1px solid #3c79db;
    box-shadow: 0 2px 4px rgba(60, 121, 219, 0.34); }
  .pricing td {
    border: 0; }
    .pricing td.info {
      text-transform: uppercase;
      vertical-align: middle; }
      .pricing td.info b {
        color: #7280ad;
        display: inline-block;
        font-size: 1.9em;
        margin-right: 10px; }
      .pricing td.info div {
        border-bottom: 1px solid #dfe6eb; }
        .pricing td.info div:hover {
          border-bottom: 1px solid #3c79db; }
        .pricing td.info div.active {
          border-bottom: 1px solid #3c79db; }
      .pricing td.info label {
        color: #3c79db;
        cursor: pointer;
        display: block;
        font-weight: 500; }
      .pricing td.info input {
        float: right;
        margin-right: 15px;
        margin-top: 10px; }
    .pricing td.t-title {
      color: #bfc7ce;
      font-size: 1.1em; }
      .pricing td.t-title b {
        color: #3c79db;
        display: block;
        font-size: 1.8em;
        margin-bottom: 10px; }
  .pricing .rep {
    color: #7280ad;
    height: 184px;
    line-height: 2.2em;
    width: 100%;
    /*div {
            border: 2px solid $color-light-blue-b;
            margin: 10px;
        }*/ }
    .pricing .rep img {
      height: 100%; }
  .pricing .rep-mobile div {
    height: 85%;
    margin-top: 14px;
    padding-top: 24px;
    text-align: center; }

.pub-here .title {
  color: #2c3a67;
  font-family: "grad", serif;
  font-size: 3.1em;
  font-weight: 700;
  margin-bottom: 20px; }

.pub-here .desc {
  color: #7280ad;
  font-size: 1.1em;
  margin-bottom: 30px; }

.pub-here h2 {
  color: #bfc7ce;
  font-family: "grad", serif;
  margin-bottom: 25px; }

form input,
form textarea {
  border-radius: 0 !important;
  box-shadow: 0 2px 4px #dfe6eb;
  font-size: 1.1em !important;
  padding: 14px !important; }
  form input[type='text'], form input[type='email'],
  form textarea[type='text'],
  form textarea[type='email'] {
    border: 1px solid #dfe6eb; }
    form input[type='text']:hover, form input[type='email']:hover,
    form textarea[type='text']:hover,
    form textarea[type='email']:hover {
      box-shadow: 0 2px 4px rgba(60, 121, 219, 0.34); }
    form input[type='text']:focus, form input[type='email']:focus,
    form textarea[type='text']:focus,
    form textarea[type='email']:focus {
      border: 1px solid #3c79db;
      box-shadow: 0 2px 4px rgba(60, 121, 219, 0.34);
      outline: none; }
  form input::-webkit-input-placeholder,
  form textarea::-webkit-input-placeholder {
    color: #bfc7ce !important; }
  form input::-moz-placeholder,
  form textarea::-moz-placeholder {
    color: #bfc7ce !important; }
  form input:-ms-input-placeholder,
  form textarea:-ms-input-placeholder {
    color: #bfc7ce !important; }

form textarea {
  border: 1px solid #dfe6eb; }
  form textarea:hover {
    box-shadow: 0 2px 4px rgba(60, 121, 219, 0.34); }
  form textarea:focus {
    border: 1px solid #3c79db;
    box-shadow: 0 2px 4px rgba(60, 121, 219, 0.34);
    outline: none; }

form button[type='submit'], form button.submit {
  background: #3c79db;
  border-radius: 0;
  box-shadow: 0 2px 4px #5288df;
  font-size: 1.1em;
  font-weight: bold;
  padding: 15px 22px;
  text-shadow: 0 1px 2px rgba(44, 58, 103, 0.5); }

form label {
  color: #777c8b;
  font-size: 1.1em;
  font-weight: 700; }
  form label.req::after {
    color: #7280ad;
    content: ' (obrigatório)';
    font-size: .7em;
    font-weight: 500; }

form .text-muted {
  color: #bfc7ce !important; }

@media only screen and (max-width: 767px) {
  .banner-horizontal {
    padding-left: 0;
    padding-right: 0; } }

@media print {
  .menu-top,
  .sidebar,
  .article-side,
  .info,
  .social,
  .rt,
  .news-box-title,
  .news-box,
  .pubwide,
  .playing,
  .publicite-aqui,
  iframe,
  footer {
    display: none !important; } }
